import React from 'react'

export default function Scroll() {
  return (
    <div>
<div class="progressParent" id="back-top">
		<svg class="backCircle svg-inner" width="100%" height="100%" viewBox="-1 -1 102 102">
			<path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
		</svg>
	</div>
	<div class="overlay"></div>   
     </div>
    )
  }