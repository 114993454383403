import React from 'react'

export default function Footer() {
  return (
    <div>
     <footer>
		<div class="footer-wrapper footer-bg-one">
			<div class="container">
				<div class="footer-area position-relative">
					<div class="row g-4">
						<div class="col-xl-3 col-lg-4 col-sm-6">
							<div class="single-footer-caption">
								<div class="footer-tittle">
									<h4 class="title">About Us</h4>
									<p class="pera">Etoshi is an exciting contemporary brand
										which focuses on high-quality products
										graphics with a British style</p>
									<ul class="info-listing">
										<li class="footer-info-list">
											<a href="#" class="single">
												<i class="ri-mail-fill"></i>
												<p class="pera">info@mydomain.com</p>
											</a>
										</li>
										<li class="footer-info-list">
											<a href="#" class="single">
												<div class="d-flex gap-6">
													<i class="ri-phone-fill"></i>
													<p class="pera">+777 2345 7885</p>
												</div>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-xl-6">
							<div class="footer-menu-section">
								<div class="logo logo-large light-logo">
									<a href="index.html"><img src="assets/images/logo/logo-dark.png" alt="logo" /></a>
								</div>
								<div class="footer-social-section">
									<h4 class="title">Follow Us</h4>
									<ul class="footer-social-lists">
										<li class="list-icon">
											<a href="javascript:void(0)" class="list">
												<i class="ri-facebook-fill"></i>
											</a>
										</li>
										<li class="list-icon">
											<a href="javascript:void(0)" class="list">
												<i class="ri-whatsapp-fill"></i>
											</a>
										</li>
										<li class="list-icon">
											<a href="javascript:void(0)" class="list">
												<i class="ri-twitter-fill"></i>
											</a>
										</li>
										<li class="list-icon">
											<a href="javascript:void(0)" class="list">
												<i class="ri-instagram-fill"></i>
											</a>
										</li>
										<li class="list-icon">
											<a href="javascript:void(0)" class="list">
												<i class="ri-linkedin-fill"></i>
											</a>
										</li>
										<li class="list-icon">
											<a href="javascript:void(0)" class="list">
												<i class="ri-pinterest-fill"></i>
											</a>
										</li>
									</ul>
								</div>
								<div class="footer-menu">
									<ul class="menu-lists">
										<li class="list">
											<a href="index.html" class="menu-list">Home</a>
										</li>
										<li class="list">
											<a href="about.html" class="menu-list">About</a>
										</li>
										<li class="list">
											<a href="shop.html" class="menu-list">Shop</a>
										</li>
										<li class="list">
											<a href="blog.html" class="menu-list">Blog</a>
										</li>
										<li class="list">
											<a href="contact.html" class="menu-list">Contact</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-xl-2 offset-xl-1 col-lg-4 col-sm-6">
							<div class="single-footer-caption">
								<div class="footer-tittle">
									<h4 class="title">My Accounts</h4>
									<ul class="listing">
										<li class="single-list"><a href="javascript:void(0)" class="single">My
												Orders</a></li>
										<li class="single-list"><a href="javascript:void(0)" class="single">My Credit
												Slips</a></li>
										<li class="single-list"><a href="javascript:void(0)" class="single">My
												Addresses</a></li>
										<li class="single-list"><a href="javascript:void(0)" class="single">My Personal
												Info</a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="footer-bottom-area position-relative">
				<div class="container">
					<div class="d-flex justify-content-between gap-14 flex-wrap">
						<div class="privacy-section d-flex">
							<a href="privacy-policy.html">
								<p class="pera mr-25">Privacy Policy</p>
							</a>
							<span>|</span>
							<a href="terms-condition.html">
								<p class="pera ml-25">Terms & Conditions</p>
							</a>
						</div>
						<div class="payment-list">
							<img src="assets/images/logo/payment-list.png" alt="img" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
    </div>
  )
}
