import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <div>
      <header>
        <div class="header-area">
          <div class="main-header">
            <div class="header-bottom header-sticky">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="menu-wrapper">
                      <div class="main-menu">
                        <nav>
                          <div class="d-flex justify-content-between align-items-center">
                            <div class="logo logo-large light-logo">
                              <Link to={"/"}>
                                <img
                                  src="assets/images/logo/logo.png"
                                  alt="logo"
                                />
                              </Link>
                            </div>
                            <div class="search-header-position d-block d-lg-none">
                              <div class="d-flex gap-15">
                                <div class="search-bar">
                                  <a
                                    href="javascript:void(0)"
                                    class="rounded-btn"
                                  >
                                    <i class="ri-search-line"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <ul
                              class="listing d-none d-lg-block"
                              id="navigation"
                            >
                              <li class="single-list">
                                <Link to={"/"} class="single active">
                                  Home
                                </Link>                                
                              </li>
                              <li class="single-list">
                                <Link to={"/about"} class="single">
                                  About Us
                                </Link>
                              </li>
                              <li class="single-list">
                                <Link to={"/contact"} class="single">
                                  Contact
                                </Link>
                              </li>
                            </ul>
                            <div class="d-none d-lg-block">
                              <div class="header-right"></div>
                            </div>
                          </div>
                        </nav>
                      </div>
                      <div class="div">
                        <div class="mobile_menu d-block d-lg-none"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
