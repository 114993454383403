import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Link,
  Typography,
  Container,
  TablePagination,
  TextField,
  Modal,
  Box,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

export default function Data() {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetch("https://fakerapi.it/api/v1/users?_quantity=1000")
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK") {
          setUsers(data.data);
        }
      })
      .catch((error) => console.error("Error fetching the data:", error));
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const handleAvatarClick = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const filteredUsers = users.filter(
    (user) =>
      user.firstname.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.lastname.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.website.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const paginatedUsers = filteredUsers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - paginatedUsers.length)
      : 0;

  console.log(paginatedUsers);

  return (
    <>
      <Typography variant="h4" component="h1" gutterBottom></Typography>
      <Container>
        <Typography variant="h4" component="h1" gutterBottom>
          Data Table
        </Typography>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <TableContainer component={Paper}>
          <Table className="table">
            {" "}
            {/* Add 'table' class for Bootstrap styling */}
            <TableHead>
              <TableRow>
                <TableCell>Avatar</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Website</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedUsers.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>
                    <Avatar
                      alt={`${user.firstname} ${user.lastname}`}
                      src={user.image}
                      onClick={() => handleAvatarClick(user)}
                    />
                  </TableCell>
                  <TableCell>{user.firstname}</TableCell>
                  <TableCell>{user.lastname}</TableCell>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <Link
                      href={user.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {user.website}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" gutterBottom>
              User Details
            </Typography>
            {selectedUser && (
              <div>
                <Typography variant="body1" gutterBottom>
                  <strong>Name:</strong>{" "}
                  {`${selectedUser.firstname} ${selectedUser.lastname}`}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Username:</strong> {selectedUser.username}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Email:</strong> {selectedUser.email}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Website:</strong>{" "}
                  <Link
                    href={selectedUser.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {selectedUser.website}
                  </Link>
                </Typography>
              </div>
            )}
          </Box>
        </Modal>
      </Container>
    </>
  );
}
