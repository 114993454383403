import React from "react";
import Home from "./pages/Home";
import Aboute from "./pages/Aboute";
import Contact from "./pages/Contact";
import Data from "./pages/Data";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/home" exact element={<Home />} />
        <Route path="/about" exact element={<Aboute />} />
        <Route path="/contact" exact element={<Contact/>} />
        <Route path="/data" exact element={<Data/>} />

      </Routes>
    </Router>
  );
}

export default App;
